<template>
  <!-- <div class="panoramic-containei" v-if="!showModel">
    <div class="comback-box" @click="handleComback">返回</div>
    <iframe
      :src="jumpUrl"
      scrolling="yes"
      frameborder="0"
      style="height: 100vh; width: 100%"
    >
    </iframe>
  </div> -->
  <div class="microWebsite-container" v-if="showModel">
    <header class="header">
      <van-nav-bar
        title="微官网"
        left-arrow
        @click-left="handleBlack"
        right-text="分享"
        @click-right="handleShareWebsite"
        safe-area-inset-top
        v-if="showHeader"
      >
      </van-nav-bar>
    </header>
    <article class="article">
      <!-- <div class="cover-img">
        <img src="../../assets/images/case-defaultimg.png" />
      </div> -->
      <div class="webesite-swip" v-if="showBanner">
        <van-swipe class="my-swipe" :autoplay="3000" lazy-render>
          <van-swipe-item
            v-for="item in website.bannerImgVOList"
            :key="item"
            @click="loadSwiperWebsite(item.jumpUrl)"
          >
            <img :src="imgUrl + '/' + item.bannerImgUrl" />
          </van-swipe-item>
        </van-swipe>
      </div>

      <div class="company-profile">
        <div>公司简介</div>
        <span> {{ website.merchantProfile }}</span>
      </div>
      <div class="website-content">
        <div class="website-content-box">
          <transform-html :contents="website.content"></transform-html>
        </div>
      </div>
      <div class="contact-us">联系我们</div>
      <div class="company-info">
        <img
          :src="website.logoUrl ? `${imgUrl}/${website.logoUrl}` : defaultLogo"
          alt=""
          class="company-logo"
        />
        <div class="company-info-describe">
          <p>{{ website.merchantName }}</p>
          <p>
            公司地址：{{
              website.merchantAreaName
                ? website.merchantAreaName + website.address
                : ""
            }}
          </p>
        </div>
      </div>
      <div class="company-phone">
        <p>联系电话</p>
        <p>
          {{ website.phoneNum }}&nbsp;&nbsp;&nbsp;&nbsp;<icon-svg
            iconClass="icondianhualianxi"
          />
        </p>
      </div>
      <div class="company-QRcode">
        <p>二维码</p>
        <div class="QRcode-img">
          <img :src="QRcodeSrc" alt="" />
        </div>
      </div>
    </article>
    <!-- 分享框 -->
    <div class="share_box">
      <van-share-sheet
        v-model:show="showShare"
        title="分享"
        :options="options"
        cancel-text="取消"
        @select="onSelect"
        safe-area-inset-bottom
      >
      </van-share-sheet>
    </div>
  </div>
</template>
<script>
import {computed, reactive, toRefs} from "vue";
import transformHtml from "../../components/transformHTML.vue";
import { useRouter,useRoute } from "vue-router";
import { findWebsiteApp,getWebsiteDetailsUrl } from "../../api/microWebsite";
import { flutterShowToast } from "../../utils/appChannel";
import { Dialog, Toast } from "vant";
import {addForwardLog} from "../../api/user/userCard";
import { copyStr } from "@/utils/commonUtil";

  export default {
  components: {
    transformHtml,
  },
  setup() {
    const router = useRouter();
    const route = useRoute()
    let showHeader = computed(() => route.query.hideTitleBar != 1);
    // const reg = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\*\+,;=.]+$/;
    const state = reactive({
      defaultLogo: require("../../assets/images/company-logo-default.png"),
      showBanner: true,
      showImg: true,
      QRcodeSrc: "",
      jumpUrl: "",
      showModel: true,
      imgUrl: process.env.VUE_APP_WATCH_FILE_API,
      showShare: false,
      website: [],
      options: [
        { name: "微信", icon: require("@/assets/icons/weChat2.png") },
        { name: "朋友圈", icon: require("@/assets/icons/friends2.png") },
        { name: "复制链接", icon: require("@/assets/icons/copy-link.png") },
      ],
    });
    Toast.loading({
      message: "加载中...",
      forbidClick: false,
    });
    const handleBlack = () => {
      router.push({ path: "/" });
    };

    const loadMicroWebsite = async () => {
      let res = await findWebsiteApp();
      if (res) {
        console.log("官网信息", res);
        if (res.isInit !== 1) {
          console.log("res.isInit", res.isInit);
          Dialog.alert({
            title: "提示",
            message: "首次进入微官网,\n请先前往优家宝web进行官网设置。",
          }).then(() => {
            router.push({ path: "/" });
          });
        } else {
          state.website = res;
          if (!res.bannerImgVOList) {
            state.showBanner = false;
          }
          if (res.isQrCode === 1) {
            state.QRcodeSrc = `${state.imgUrl}/${res.qrCodeUrl}`;
          } else {
            state.QRcodeSrc = res.userCardVO.qrCode;
          }
        }
      }
      Toast.clear();
    };
    loadMicroWebsite();

    //点击轮播图
    const loadSwiperWebsite = (it) => {
      console.log("轮播图信息：", it);
      const reg = /^(?:http(s)?:\/\/)?[\w.-]+(?:[\w-]+)+[\w\-_~:/?#[\]@!&',;=.]+$/;
      let iSweb = reg.test(it);
      if (iSweb) {
        state.showModel = !state.showModel;
        state.jumpUrl = it.indexOf("https") === -1
              ? `https://${it}`
              : it;
        router.push({
          name: "panoramicWindow",
          query: { panoramicUrl: state.jumpUrl },
        });
      }
    };
    //电话
    const handleCall = () => {
      if (state.website.phoneNum) {
        let a = document.createElement("a");
        a.setAttribute("href", `tel:${state.website.phoneNum}`);
        a.click();
      }
    };

    const handleComback = () => {
      state.showModel = !state.showModel;
    };
    const handleShareWebsite = () => {
      state.showShare = true;
    };
    //分享微信
    const onSelect = (index) => {
      console.log("分享到哪里：", index);
      if (index.name === "复制链接"){
        getWebsiteDetailsUrl(state.website.websiteId).then((res)=>{
          console.log(res)
          copyStr(res)
          Toast.success("复制成功")
          state.showShare = false
        })

      }else {
        let shareType;
        let content;
        let merchantId = localStorage.getItem('merchantId')
        addForwardLog(5).then((res)=>{
          console.log("分享日志：",res);
        })
        if (index.name === "微信") {
          shareType = "shareToMiniProgram";
          content = `${process.env.VUE_APP_WECHAT_SHARE}/microWebsiteShare/1/${merchantId}`;
        } else if (index.name === "朋友圈") {
          shareType = "shareH5PageToTimeLine";
          content = `${process.env.VUE_APP_WECHAT_SHARE}/microWebsiteShare/2/${merchantId}`;
        }

        let title = state.website.merchantName
            ? `${state.website.merchantName}的微官网`
            : "微官网";
        let desc = state.website.merchantProfile
            ? `${state.website.merchantProfile}`
            : "公司介绍";
        let param = {
          miniProgramType:state.website.jumpApplets,
          shareType: shareType,
          urlEntity: {
            title: `${process.env.VUE_APP_WECHAT_SHARE}/shareForward?shareUrl=`,
            content: content,
          },
          title: title,
          desc: desc,
          shareImg: state.website.logoUrl
              ? `${process.env.VUE_APP_WATCH_FILE_API}/${state.website.smallLogoUrl}`
              : "https://wca.ujiaku.com/upload/wx/weiGuanWang/microWebsite-defaultImg.jpg",
        };
        flutterShowToast(param);
        state.showShare = false;
      }
    };

    return {
      ...toRefs(state),
      handleCall,
      handleComback,
      loadMicroWebsite,
      onSelect,
      handleBlack,
      handleShareWebsite,
      loadSwiperWebsite,
      showHeader
    };
  },
};
</script>
<style lang="scss">
.microWebsite-container {
  .header {
    .van-nav-bar__arrow {
      color: #333333;
    }
    .van-nav-bar__title {
      color: #333333;
      font-weight: 600;
    }
    .van-nav-bar__text {
      color: #ff6e01;
    }
  }
  .share_box {
    .van-share-sheet__cancel::before {
      height: 0;
    }
    .van-share-sheet__cancel {
      height: 48px;
      width: 343px;
      background-color: #f7f7f7;
      color: #333333;
      border-radius: 5px;
      margin-left: 15px;
      margin-bottom: 15px;
      margin-top: 15px;
    }
    .van-share-sheet__options {
      color: #333333;
      display: flex;
      padding: 0 20px;
      justify-content: space-around;
    }
  }
}
</style>
<style lang="scss" scoped>
.panoramic-containei {
  position: relative;
  ::-webkit-scrollbar {
    display: none;
  }
  .comback-box {
    position: absolute;
    height: 36px;
    width: 60px;
    line-height: 36px;
    text-align: center;
    left: 0;
    top: 100px;
    color: white;
    font-size: 14px;
    background: rgba(46, 46, 46, 0.5);
    border-radius: 0 20px 20px 0;
    opacity: 0.5;
  }
}
.microWebsite-container {
  font-size: 16px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  text-align: left;
  .article {
    flex: 1;
    overflow-x: hidden;
    background: #e5e5e5;
    .webesite-swip {
      height: 160px;
      padding: 0 16px;
      .my-swipe {
        border-radius: 4px;
        height: 100%;
        width: 100%;

        box-shadow: 1px 2px 8px 4px #6666;
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }
    .company-profile {
      text-align: left;
      background-color: #fff;
      padding: 12px 16px;
      margin: 5px 0;
      :nth-child(1) {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 9px;
      }
      > span {
        font-weight: 400;
        font-size: 14px;
        color: #666666;
      }
    }
    .website-content {
      width: 100%;
      padding: 5px 16px;
      font-size: 14px;
      text-align: left;
      box-sizing: border-box;
      background-color: #ffffff;
      .website-content-box {
        img {
          width: 100%;
        }
        p {
          margin: 0;
          padding: 0;
        }
      }
    }
    .contact-us {
      padding: 8px 16px;
      font-weight: 600;
      font-size: 14px;
    }
    .company-info {
      background-color: #fff;
      padding: 16px;
      display: flex;
      justify-content: space-between;
      > img {
        display: block;
        height: 40px;
        width: 40px;
        border-radius: 20px;
      }
      .company-info-describe {
        flex: 1;
        margin-left: 8px;
        > p {
          margin: 0;
          padding: 0;
        }
        > :nth-child(1) {
          font-weight: 600;
          font-size: 14px;
        }
        > :nth-child(2) {
          font-weight: 400;
          font-size: 11px;
          color: rgba(0, 0, 0, 0.4);
        }
      }
    }
    .company-phone {
      background-color: #fff;
      padding: 16px;
      margin: 5px 0;
      > p {
        margin: 0;
        padding: 0;
      }
      > :nth-child(1) {
        font-weight: 400;
        font-size: 11px;
        color: rgba(0, 0, 0, 0.4);
      }
      > :nth-child(2) {
        font-weight: 600;
        font-size: 18px;
        line-height: 26px;
      }
    }
    .company-QRcode {
      height: 266px;
      background-color: #fff;
      padding: 16px;
      > p {
        margin: 0;
        padding: 0;
        font-weight: 600;
        font-size: 14px;
      }
      .QRcode-img {
        text-align: center;
        img {
          height: 208px;
          width: 208px;
        }
      }
    }
  }
}
</style>
